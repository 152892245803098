import { useCallback, useEffect, useState } from 'react';
import Router from 'next/router';
import server from '@services/api';
import { checkToken, getOauthTokenRoute } from '@features/auth/ts/routes';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
import LocalStorageService from './localStorage';

interface iSession {
  redirectTo?: string;
  redirectIfFound?: boolean;
  roles?: string[];
  iframe?: boolean;
}

const localStorageService = LocalStorageService.getService();

const fetchCheckToken = async () => {
  const accessToken = LocalStorageService.getItem('access_token');
  const refreshToken = LocalStorageService.getItem('refresh_token');

  if (!accessToken || !refreshToken) {
    return null;
  }

  try {
    const response = await server({
      method: 'get',
      url: checkToken(),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (e) {
    try {
      const response = await server({
        method: 'post',
        url: getOauthTokenRoute(),
        data: `grant_type=refresh_token&refresh_token=${refreshToken}`,
        auth: {
          username: process.env.NEXT_PUBLIC_USER,
          password: process.env.NEXT_PUBLIC_PASSWORD,
        },
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      });
      return response;
    } catch (refreshError) {
      localStorageService.clearOnLogout();
      return refreshError;
    }
  }
};

const session = ({ redirectTo, redirectIfFound, roles, iframe }: iSession): any => {
  let self = true;

  if (window.location !== window.parent.location) {
    self = false;
  }

  const [user, setUser] = useState({
    isLoggedIn: false,
  });

  const fetchToken = useCallback(async () => {
    fetchCheckToken().then((response) => {
      if (
        // When user has role different than none
        response &&
        response.status === 200 &&
        response.data?.data?.user?.roles.indexOf('none') < 0
      ) {
        const { _id, names, surnames, email, roles: rolesAux } = response.data?.data?.user;
        LogRocket.identify(_id, {
          name: `${names} ${surnames}`,
          roles: rolesAux,
          email,
        });
        Sentry.setUser({ _id, email, roles: rolesAux, name: `${names} ${surnames}` });
        setUser({
          isLoggedIn: true,
        });
        if (redirectIfFound) {
          if (self) {
            Router.push(redirectTo);
          } else {
            localStorageService.clearOnLogout();
          }
        }
        return;
      }
      if (
        (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
        (redirectIfFound && user?.isLoggedIn)
      ) {
        if (self) {
          Router.push(redirectTo);
        } else {
          localStorageService.clearOnLogout();
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!redirectTo || !user) {
      return;
    }
    if (!user.isLoggedIn) {
      fetchToken();
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user };
};

export default session;
